import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaArrowCircleRight } from "react-icons/fa";

export type SigninProps = {
  onSignUpCompleted: () => void;
};

const SignIn: React.FC<SigninProps> = ({ onSignUpCompleted }) => {
  const [inputValue, setInputValue] = useState("");
  const [showInvalidPasswordText, setShowInvalidPasswordText] = useState(false);

  const signInPressed = () => {
    setShowInvalidPasswordText(false);
    if (inputValue === "iforgot") {
      onSignUpCompleted();
    } else {
      setShowInvalidPasswordText(true);
    }
  };

  const imageUrl =
    "https://careers.innvolve.nl/wp-content/uploads/2022/12/Stefan1.jpg";
  return (
    <div className="w-screen h-screen bg-gradient-to-r from-violet-500 to-fuchsia-500 overscroll-none">
      <div className="flex items-center justify-center h-full align-middle">
        <div className="flex flex-col w-[15rem] justify-center h-full items-center align-middle">
          <img
            className="rounded-full "
            width={100}
            height={100}
            alt="Stefan Vasilev"
            src={imageUrl}
          />
          <div className="text-xl text-white">Stefan Vasilev</div>
          <div className="flex flex-row">
            <input
              type="password"
              value={inputValue}
              onChange={(e) => {
                setShowInvalidPasswordText(false);
                setInputValue(e.target.value)
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  console.log("Enter key pressed");
                  signInPressed();
                }
              }}
              className="p-1 mt-2 text-white placeholder-gray-200 bg-white bg-opacity-25 rounded-lg"
              placeholder="Enter password"
            />
            <button
              onClick={signInPressed}
              className="mt-2 ml-1 text-gray-200 opacity-50"
            >
              <FaArrowCircleRight size={20} />
            </button>
          </div>
          {showInvalidPasswordText && (
            <motion.div
              className="mt-2 text-red-800"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
              transition={{
                delay: 5,
                duration: 0.5,
                y: { type: "spring", stiffness: 50 },
              }}
            >
              invalid password
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
