import { Certificate } from "../models/Certificate";
import { CertificateProvider } from "../models/CertificateProvider";

const Certificates = (): Certificate[] => {
  const awsProvider: CertificateProvider = {
    name: "AWS",
    colour: "text-[#FF9900]",
  };
  const azureProvider: CertificateProvider = {
    name: "Azure",
    colour: "text-[#069AF3]",
  };
  const csharpProvider: CertificateProvider = {
    name: "C#",
    colour: "text-[#613077]",
  };

  return [
    {
      name: "Amazon AWS Certified Developer",
      inProgress: true,
      isDone: false,
      provider: awsProvider,
    },
    {
      name: "AZ-900: Microsoft Azure Fundamentals",
      inProgress: false,
      isDone: true,
      provider: azureProvider,
    },
    {
      name: 'AZ-204: Developing Solutions for Microsoft Azure"',
      inProgress: false,
      isDone: true,
      provider: azureProvider,
    },
    {
      name: "AZ-400: Designing and Implementing Microsoft DevOps Solutions",
      inProgress: false,
      isDone: true,
      provider: azureProvider,
    },
    {
      name: "70-483: Developing in C#",
      inProgress: false,
      isDone: true,
      provider: csharpProvider,
    },
  ];
};

export default Certificates;