import AppTypes from "../enums/AppTypes";
import WindowAppIcon from "./WindowAppIcon";

type WindowAppScreenProps = {
  onClick: (appName: AppTypes) => void;
};

const WindowAppScreen: React.FC<WindowAppScreenProps> = ({ onClick }) => {
  return (
    <div className="grid grid-cols-5 grid-rows-3 gap-4">
      <div
        style={{
          gridColumn: 3,
          gridRow: 2,
        }}
      >
        <WindowAppIcon
          name="Terminal"
          appType={AppTypes.Terminal}
          color="black"
          onClick={() => onClick(AppTypes.Terminal)}
        />
      </div>
      <div
        style={{
          gridColumn: 1,
          gridRow: 2,
        }}
      >
        <WindowAppIcon
          name="stefan-vasilev-cv-2023"
          appType={AppTypes.CV}
          color="red"
          onClick={() => onClick(AppTypes.CV)}
        />
      </div>

      <div
        style={{
          gridColumn: 1,
          gridRow: 3,
        }}
      >
        <WindowAppIcon
          name="Contact"
          appType={AppTypes.Contact}
          color="blue"
          onClick={() => onClick(AppTypes.Contact)}
        />
      </div>

      <span
      className="text-3xl font-bold text-center text-orange-500 select-none"
        style={{
          gridColumn: 2,
          gridRow: 3,
        }}
      >
       UNDER CONSTRUCTION
      </span>


      <div
        style={{
          gridColumn: 2,
          gridRow: 2,
        }}
      >
        <WindowAppIcon
          name="Blogs"
          appType={AppTypes.Blogs}
          color="orange"
          onClick={() => onClick(AppTypes.Blogs)}
        />
      </div>
    </div>
  );
};

export default WindowAppScreen;
