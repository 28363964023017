import { useEffect, useState } from "react";
import { AiFillApple, AiFillSound } from "react-icons/ai";
import { BiWifi2, BiSearchAlt2, BiBluetooth } from "react-icons/bi";
import { BsBatteryHalf } from "react-icons/bs";
import { FaDocker } from "react-icons/fa";

type WindowScreenMenuBarProps = {
  appName: string;
};

const WindowScreenMenuBar: React.FC<WindowScreenMenuBarProps> = ({
  appName,
}) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formattedTime = currentTime.toLocaleString("en-GB", {
    weekday: "short", // "Sat"
    day: "2-digit", // "11"
    month: "short", // "Nov"
    hour: "2-digit", // "01"
    minute: "2-digit", // "15"
  });

  return (
    <div className="w-full h-full bg-gray-900 bg-opacity-40">
      {/* menu items */}
      <div className="flex flex-row justify-between mx-3 space-x-4">
        {/* app row */}
        <div className="flex flex-row justify-start gap-3 align-middle">
          <div>
            <AiFillApple size={24} />
          </div>
          <div className="font-extrabold text-md">{appName}</div>
        </div>

        {/* menu row */}
        <div className="flex flex-row justify-end gap-3 align-middle">
          {/* menu icons */}
          <div className="hidden md:block">
            <FaDocker size={24} />
          </div>
          <div className="hidden md:block">
            <BsBatteryHalf size={24} />
          </div>
          <div className="hidden md:block">
            <BiBluetooth size={24} />
          </div>
          <div className="hidden md:block">
            <AiFillSound size={24} />
          </div>
          <div className="hidden md:block">
            <BiSearchAlt2 size={24} />
          </div>
          <div className="hidden md:block">
            <BiWifi2 size={24} />
          </div>
          <div>{formattedTime}</div>
        </div>
      </div>
    </div>
  );
};

export default WindowScreenMenuBar;
