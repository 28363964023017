import { useState } from "react";
import "./App.css";
import WindowScreen from "./components/WindowScreen";
import Startup from "./components/HomeScreen/Startup";
import SignIn from "./components/HomeScreen/SignIn";

function App() {
  const [showWindow, setShowWindow] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const showWindowScreen = () => {
    setShowWindow(true);
    setShowSignIn(false);
  };
  return (
    <div>
      {!showSignIn && !showWindow && (
        <Startup onStartupCompleted={() => setShowSignIn(true)} />
      )}
      {showSignIn && !showWindow && (
        <SignIn onSignUpCompleted={() => showWindowScreen()} />
      )}
      {showWindow && <WindowScreen />}
    </div>
  );
}

export default App;
