import { BsFillTerminalFill } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { IoIosBook } from "react-icons/io";

type WindowDockingMenuProps = {
  onTerminalClick: () => void;
//   onCVClick: () => void;
  onContactClick: () => void;
};

const WindowDockingMenu: React.FC<WindowDockingMenuProps> = ({
  onTerminalClick,
//   onCVClick,
  onContactClick,
}) => {
  return (
    <div className="flex flex-row justify-center w-full gap-2 p-1 mx-20 align-middle bg-gray-500 border-2 border-white border-solid rounded-lg border-opacity-10 bg-opacity-30">
      <button onClick={onTerminalClick}>
        <BsFillTerminalFill size={40} color="black" />
      </button>
      {/* <button onClick={onCVClick}>
        <AiFillFilePdf size={40} color="red" />
      </button> */}
      <button onClick={onContactClick}>
        <FiMail size={40} color="blue" />
      </button>
      <button >
        <IoIosBook size={40} color="orange" />
      </button>
    </div>
  );
};

export default WindowDockingMenu;
