import { BsFillTerminalFill } from "react-icons/bs";
import { AiFillFilePdf } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import { IoIosBook } from "react-icons/io";
import AppTypes from "../enums/AppTypes";

type WindowAppIconProps = {
  name: string;
  appType: AppTypes;
  color: string;
  onClick: () => void;
};

const WindowAppIcon: React.FC<WindowAppIconProps> = ({
  name,
  appType,
  color,
  onClick,
}) => {
  return (
    <div>
      <button
        className="flex flex-col items-center justify-center p-4 space-y-2 hover:cursor-pointer"
        onClick={onClick}
      >
        {appType === AppTypes.Terminal && (
          <BsFillTerminalFill size={50} color={color} />
        )}
        {appType === AppTypes.CV && <AiFillFilePdf size={50} color={color} />}
        {appType === AppTypes.Contact && <FiMail size={50} color={color} />}
        {appType === AppTypes.Blogs && <IoIosBook size={50} color={color} />}
        {name}
      </button>
    </div>
  );
};

export default WindowAppIcon;
