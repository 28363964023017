import { AnimatePresence, motion } from "framer-motion";
import Terminal from "./Apps/Terminal/Terminal";
import AppTypes from "../enums/AppTypes";
import AppScreenMenuBar from "./AppScreenMenuBar";
import Contact from "./Apps/Contact/Contact";
import CV from "./Apps/CV/CV";

type AppScreenProps = {
  appType: AppTypes;
  appTitle: string;
  bgColor: string;
  bgOpacity: string;
  textColor: string;
  onCloseClicked: () => void;
};

const AppScreen: React.FC<AppScreenProps> = ({
  appType,
  appTitle,
  bgColor,
  bgOpacity,
  textColor,
  onCloseClicked,
}) => {
  const screenClass = `w-full h-[60vh] md:h-[70vh] mx-4 ${bgColor} ${bgOpacity} border-1 rounded-b-xl ${textColor}`;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 0, x: 0, opacity: 0 }}
        animate={{ y: 60, opacity: 1 }}
        exit={{ y: 300, x: 0, opacity: 0 }}
        className="flex flex-col justify-center h-full"
      >
        {/* top bar */}
        <AppScreenMenuBar appTitle={appTitle} onCloseClicked={onCloseClicked} />
        {/* contentscreen */}
        <div className={screenClass}>
          {/* content */}
          {appType === AppTypes.Terminal && <Terminal />}
          {appType === AppTypes.Contact && <Contact formSubmitted={() => onCloseClicked()}  />}
          {appType === AppTypes.CV && <CV/>}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AppScreen;
