import { motion } from "framer-motion";
import React from "react";

export type StartupProps = {
  onStartupCompleted: () => void;
};

const Startup: React.FC<StartupProps> = ({ onStartupCompleted }) => {
  const progressBar = {
    initial: { width: 0 },
    animate: { width: "100%" },
  };

  return (
    <div className="w-screen h-screen bg-black">
      <div className="flex items-center justify-center h-full align-middle">
        <div className="flex flex-col w-[15rem] justify-center h-full items-center align-middle">
          <img
            width={50}
            height={50}
            alt="Apple logo"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Apple_logo_grey.svg/1724px-Apple_logo_grey.svg.png"
          />
          <div className="w-full mt-5 bg-[#4d4d4d] rounded-full">
            <motion.div
              className="h-2 bg-[#eeeeee] rounded-full"
              variants={progressBar}
              initial="initial"
              animate="animate"
              transition={{ duration: 3 }}
              onAnimationComplete={() => onStartupCompleted()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Startup;
