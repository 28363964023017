import { useState } from "react";

export type ContactProps = {
  formSubmitted: () => void;
};

const Contact: React.FC<ContactProps> = ({ formSubmitted }) => {
  const contactFormUrl =
    "https://kvu7cjt71c.execute-api.eu-west-1.amazonaws.com/send-mail-stage";
  const [subject, setSubject] = useState("");
  const [from, setFrom] = useState("");
  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (!subject || !from || !message) {
      alert("Please fill in all fields");
      return;
    }

    const data = {
      subject: subject,
      senderName: from,
      message: message,
    };

    fetch(contactFormUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 200) {
          formSubmitted();
        } else {
          alert("Message not sent");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex flex-col h-full mt-2 mr-10 ">
        <div className="mt-2">
          <label className="ml-4">
            To:
            <input
              className="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="email"
              disabled={true}
              value="Stefan Vasilev"
            />
          </label>
        </div>

        <div className="w-full mt-2 ">
          <label className="ml-4">
            Subject:
            <input
              className="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </label>
        </div>

        <div className="w-full mt-2">
          <label className="ml-4">
            From:
            <input
              className="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="email"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </label>
        </div>

        <div className="w-full h-full mt-4 ml-4">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="block p-2.5 w-full h-[10rem] max-h-[10rem] md:h-[15rem] md:max-h-[16rem]  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="flex justify-center w-full mt-2 ml-4">
          <button
            onClick={handleSend}
            className="w-full px-4 py-2 font-semibold text-blue-700 bg-transparent border border-blue-500 rounded hover:bg-blue-500 hover:text-white hover:border-transparent"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
