import React, { useState } from "react";
import { Line } from "./Line";
import TerminalLine from "./TerminalLine";
import Certificates from "../../../data/Certificates";
import Skills from "../../../data/Skills";

const getInputCertificates = (): Line[] => {
  const lines: Line[] = [];

  const certificates = Certificates(); 

  certificates.forEach(certificate => {
    // Add provider name with color only if it's the first occurrence
    if (!lines.some(line => line.text === certificate.provider.name)) {
      lines.push({
        text: certificate.provider.name,
        style: `${certificate.provider.colour} text-2sm md:text-2xl`,
      });
    }

    // Determine the text based on the certificate's status
    const certText = certificate.isDone
      ? `✓ ${certificate.name}`
      : `👨‍💻 ${certificate.name}`

    // Add certificate with or without style based on inProgress status
    lines.push({
      text: certText,
      style: certificate.inProgress ? "text-1sm md:text-4sm" : "",
    });
  });

  return lines;
};

const getInputSkills = (): Line[] => {
  const lines: Line[] = [];
  const skills = Skills(); 

  skills.forEach(skill => {
    lines.push({text: skill.name, style: ''})
  })
  
  return lines;
};

const getInputCommandList = (): Line[] => {
  return [
    { text: "Use the following commands:", style: "" },
    { text: "sv --skills", style: "ml-4", isCommand: true },
    { text: "sv --certificates", style: "ml-4", isCommand: true },
    { text: "help", style: "ml-4", isCommand: true },
    { text: "clear", style: "ml-4", isCommand: true },
  ];
};

const Terminal: React.FC = () => {
  const [lines, setLines] = useState<Line[]>([
    { text: "Welcome on my personal website", style: "" },
    ...getInputCommandList(),
  ]);
  const [inputValue, setInputValue] = useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleInputValue(inputValue);
      setInputValue(""); // Clear the input after submitting
    }
  };

  const getInputLineValue = (command: string, lines: Line[]): Line[] => {
    return [{ text: `> ${command}` }, ...lines];
  };

  const handleInputValue = (value: string) => {
    if (value !== "") {
      if (value === "sv --skills") {
        const values = getInputLineValue(value, getInputSkills());
        setLines([...lines, ...values]);
      } else if (value === "sv --certificates") {
        const values = getInputLineValue(value, getInputCertificates());
        setLines([...lines, ...values]);
      } else if (value === "clear") {
        const emptyArray: Line[] = [];
        setLines([...emptyArray]);
      } else if (value === "help") {
        const values = getInputLineValue(value, getInputCommandList());
        setLines([...lines, ...values]);
      } else {
        const newLine: Line = {
          text: `command: '${value}' not found, use 'help' to see possible list of commands`,
          style: "text-red-600",
        };

        const values = getInputLineValue(value, [newLine]);
        setLines([...lines, ...values]);
      }
    }

    inputRef.current?.focus();
    scrollToBottom();
  };

  const handleOnCommandClicked = (command: string) => {
    handleInputValue(command);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  return (
    <div
      ref={messagesEndRef}
      onClick={() => inputRef.current?.focus()}
      className="w-full h-full p-4 my-2 overflow-scroll font-mono text-white bg-black overscroll-y-contain"
    >
      {lines.map((line, index) => (
        <TerminalLine
          key={index}
          line={line}
          onLineClick={handleOnCommandClicked}
        />
      ))}
      <div className="flex flex-row gap-2 ml-2">
        <div className="text">~</div>
        <input
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputSubmit}
          className="w-full text-white bg-transparent outline-none"
          autoFocus
        />
      </div>
    </div>
  );
};

export default Terminal;
