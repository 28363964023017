import { Line } from "./Line";

type TerminalLineProps = {
  line: Line;
  onLineClick?: (command: string) => void;
};

const commandOptionLine = (
  line: Line,
  lineClick: (command: string) => void
) => {
  return (
    <div>
      <span
        className="text-blue-400 hover:underline hover:decoration-solid hover:cursor-pointer"
        onClick={() => lineClick(line.text)}
      >
        {line.text}
      </span>
    </div>
  );
};

const resultOptionLine = (line: Line) => {
  return (
    <div>
      <span className={line.style}>{line.text}</span>
    </div>
  );
};

const TerminalLine: React.FC<TerminalLineProps> = ({ line, onLineClick }) => {
  return (
    <div className="py-1 ml-5">
      {line.isCommand
        ? commandOptionLine(line, onLineClick!)
        : resultOptionLine(line)}
    </div>
  );
};

export default TerminalLine;
