import { AiOutlineClose } from "react-icons/ai";

type AppScreenMenuBarProps = {
  onCloseClicked: () => void;
  appTitle: string;
};

const AppScreenMenuBar: React.FC<AppScreenMenuBarProps> = ({
  onCloseClicked,
  appTitle,
}) => {
  return (
    <div className="w-full h-[35px] md:h-[40px] mx-4 bg-gray-300 border-solid border-1 rounded-t-xl">
      <div className="flex flex-row justify-start mx-4 text-black">
        {/* menu buttons */}
        <div className="w-[80px] flex flex-row justify-evenly  mt-3">
          {/* close */}
          <button
            className="w-[16px] h-[16px] rounded-full bg-red-500 group hover:cursor-auto"
            onClick={onCloseClicked}
          >
            <div className="flex justify-center hidden align-middle group-hover:block">
              <AiOutlineClose />
            </div>
          </button>
          <button className="w-[16px] h-[16px] rounded-full bg-orange-500 hover:cursor-auto" />
          <button className="w-[16px] h-[16px] rounded-full bg-green-500 hover:cursor-auto" />
        </div>
        {/* appname */}
        <p className="flex flex-row justify-center w-full h-full m-auto text-sm font-semibold text-center text-black align-middle">
          {appTitle}
        </p>
      </div>
    </div>
  );
};

export default AppScreenMenuBar;
