import { useState } from "react";
import WindowAppScreen from "./WindowAppScreen";
import AppTypes from "../enums/AppTypes";
import AppScreen from "./AppScreen";
import WindowScreenMenuBar from "./WindowScreenMenuBar";
import WindowDockingMenu from "./WindowDockingMenu";

const WindowScreen = () => {
  const [showApp, setShowApp] = useState(false);
  const [showTerminal, setShowTerminal] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showCV, setShowCV] = useState(false);
  const [appName, setAppName] = useState("");

  const showTerminalScreen = (show: boolean) => {
    setShowTerminal(show);
    setShowContact(false);
    setShowCV(false);
    setShowApp(show);
    setAppName(show ? "Terminal" : "");
  };

  const showContactScreen = (show: boolean) => {
    setShowTerminal(false);
    setShowCV(false);
    setShowContact(show);
    setShowApp(show);
    setAppName(show ? "Contact" : "");
  };

  const showCVScreen = (show: boolean) => {
    setShowTerminal(false);
    showContactScreen(false);
    setShowCV(show);
    setShowApp(show);
    setAppName(show ? "stefan-vasilev-cv-2023.pdf" : "");
  };

  const appIconClick = (appType: AppTypes) => {
    if (appType === AppTypes.Terminal) {
      showTerminalScreen(true);
    }
    if (appType === AppTypes.Contact) {
      showContactScreen(true);
    }

    if (appType === AppTypes.CV) {
      showCVScreen(true);
    }
  };

  return (
    <div className="relative grid w-screen h-[100vh] grid-cols-1 text-gray-300 bg-gradient-to-r from-violet-500 to-fuchsia-500 overscroll-none">
      {/* menu bar */}
      <div className="absolute w-full align-top">
        <WindowScreenMenuBar appName={appName} />
      </div>
      {/* content */}
      <div className="absolute inset-0 mx-5">
        {/* desktop layer */}
        <div className={`absolute inset-0 ${showApp ? "z-10" : "z-30"}  `}>
          <div className="relative mt-5">
            <WindowAppScreen onClick={appIconClick} />
          </div>
        </div>
        {/* app layer */}
        <div className={`absolute inset-0 ${showApp ? "z-30" : "z-10"}`}>
          <div className="relative mr-5">
            {showApp && showTerminal && (
              <AppScreen
                appTitle="macbookpro-van-stefan"
                appType={AppTypes.Terminal}
                bgColor="bg-black"
                bgOpacity="opacity-70"
                textColor="text-white"
                onCloseClicked={() => showTerminalScreen(false)}
              />
            )}

            {showApp && showContact && (
              <AppScreen
                appTitle="Contact"
                bgColor="bg-white"
                bgOpacity="opacity-100"
                textColor="text-black"
                appType={AppTypes.Contact}
                onCloseClicked={() => showContactScreen(false)}
              />
            )}

            {showApp && showCV && (
              <AppScreen
                appTitle="stefan-vasilev-cv-2023.pdf"
                bgColor="bg-white"
                bgOpacity="opacity-100"
                textColor="text-black"
                appType={AppTypes.CV}
                onCloseClicked={() => showCVScreen(false)}
              />
            )}
          </div>
        </div>
      </div>
      {/* docking menu */}
      <div className="fixed bottom-2 z-40 flex w-full h-[50px]">
        <WindowDockingMenu
          onTerminalClick={() => showTerminalScreen(true)}
          onContactClick={() => showContactScreen(true)}
        />
      </div>
    </div>
  );
};

export default WindowScreen;
